export const changeUserPermissions = permissions => ({
    type: "CHANGE_USER_PERMISSIONS",
    payload: {
        permissions,
    },
});

export const deleteUserPermissions = permissions => ({
    type: "DELETE_USER_PERMISSIONS",
    payload: {
        permissions,
    },
});
