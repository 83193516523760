const initialState = {
  userName: '',
  userLastname: '',
  userEmail: '',
  userPicture: '',
  isLogIn: false,
  userRole: ''
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_LOG_IN": {
      const { isLogIn } = action.payload;
      return {
        ...state,
        isLogIn,
      };
    }
    case "CHANGE_USER_DATA": {
      const { uid, name, lastname, email, picture, userRole } = action.payload;

      return {
        ...state,
        uid,
        userName: name,
        userLastname: lastname,
        userEmail: email,
        userPicture: picture,
        userRole
      };
    }
    default:
      return state
  }
}

export default userReducer
