const initialState = {
    users: []
}

const usersReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case "GET_ALL_USERS": {
            return {
                isLoading: true,
                users: []
            };
        }
        case "GET_ALL_USERS_SUCCESS": {
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        }
        case "GET_ALL_USERS_FAIL": {
            return {
                users: [],
                isLoading: false,
                ...payload
            };
        }
        default:
            return state
    }
}

export default usersReducer
