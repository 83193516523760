import firebase from 'firebase/app';
import "firebase/auth";

export const LogOut = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error)
      });
  });
}
