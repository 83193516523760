import { call, put, takeEvery } from 'redux-saga/effects'
import {getSchedulesFail, getSchedulesSuccess} from "../../actions/schedules";
import {getAllSchedules} from "../../../firebase/Schedules/Getters";


// worker Saga: will be fired on GET_SCHEDULES actions
function* getSchedules() {
    try {
        const users = yield call(getAllSchedules);
        yield put(getSchedulesSuccess(users));
    } catch (e) {
        yield put(getSchedulesFail(e.message));
    }
}

/*
  Starts fetchUser on each dispatched `GET_SCHEDULES` action.
  Allows concurrent fetches of user.
*/
function* watchSchedulesSaga() {
    yield takeEvery("GET_SCHEDULES", getSchedules);
}

export default watchSchedulesSaga;
