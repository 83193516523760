export const changeUserRole = userRole => {
  return dispatch => dispatch({ type: "CHANGE_USER_ROLE", userRole })
}

export const changeLogIn = isLogIn => ({
  type: "CHANGE_LOG_IN",
  payload: {
    isLogIn,
  },
});

export const changeUserData = data => ({
  type: "CHANGE_USER_DATA",
  payload: {
    ...data,
  },
});
