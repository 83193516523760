export const getSchedules = () => ({
    type: "GET_SCHEDULES",
    payload: {},
});

export const getSchedulesSuccess = schedules => ({
    type: "GET_SCHEDULES_SUCCESS",
    payload: {
        schedules,
    },
});

export const getSchedulesFail = error => ({
    type: "GET_SCHEDULES_FAIL",
    payload: {
        error,
    },
});
