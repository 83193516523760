const initialState = {}

const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_USER_PERMISSIONS": {
            const {permissions} = action.payload;

            return {
                ...state,
                ...permissions,
            };
        }
        case "DELETE_USER_PERMISSIONS": {
            const {permissions} = action.payload;

            return {
                ...permissions,
            };
        }
        default:
            return state
    }
}

export default permissionsReducer
