import { call, put, takeEvery } from 'redux-saga/effects'
import {getAllContactsData} from "../../../firebase/YellowPages/Getters";
import {getContactsFail, getContactsSuccess} from "../../actions/contacts";


// worker Saga: will be fired on GET_ALL_CONTACTS actions
function* getAllContacts() {
    try {
        const users = yield call(getAllContactsData);
        yield put(getContactsSuccess(users));
    } catch (e) {
        yield put(getContactsFail(e.message));
    }
}

/*
  Starts fetchUser on each dispatched `GET_ALL_CONTACTS` action.
  Allows concurrent fetches of user.
*/
function* watchContactsSaga() {
    yield takeEvery("GET_ALL_CONTACTS", getAllContacts);
}

export default watchContactsSaga;
