export const getUsers = () => ({
    type: "GET_ALL_USERS",
    payload: {},
});

export const getUsersSuccess = users => ({
    type: "GET_ALL_USERS_SUCCESS",
    payload: {
        users,
    },
});

export const getUsersFAIL = error => ({
    type: "GET_ALL_USERS_FAIL",
    payload: {
        error,
    },
});
