import { call, put, takeEvery, take} from 'redux-saga/effects'
import {getDayTripFail, getDayTripSuccess} from "../../actions/dayTrip";
import {getDayTripsByState} from "../../../firebase/DayTrip/Getters";


// worker Saga: will be fired on GET_DAY_TRIP actions
function* getAllDayTrips({status, year}) {
    try {
        const trips = yield call(getDayTripsByState, status);
        yield put(getDayTripSuccess(trips));
    } catch (e) {
        yield put(getDayTripFail(e.message));
    }
}

/*
  Starts fetchUser on each dispatched `GET_DAY_TRIP` action.
  Allows concurrent fetches of user.
*/
function* watchActiveDayTripsSaga() {
    const {payload} = yield take("GET_DAY_TRIP");
    yield call(getAllDayTrips, payload)
}

export default watchActiveDayTripsSaga;
