const initialState = {
    activeDayTrip: '',
    inactiveDayTrip: '',
    inactiveDayTripList: [],
    activeDayTripList: [],
    active: [],
    inactive:[]
}

const dayTripReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case "CHANGE_COUNT_ACTIVE_DAY_TRIP": {
            return {
                ...state,
                payload,
            };
        }
        case "CHANGE_COUNT_INACTIVE_DAY_TRIP": {
            return {
                ...state,
                payload,
            };
        }
        case "CHANGE_ACTIVE_DAY_TRIP_LIST": {
            return {
                ...state,
                payload,
            };
        }
        case "CHANGE_INACTIVE_DAY_TRIP_LIST": {
            return {
                ...state,
                payload,
            };
        }
        // Reducers for saga
        case "GET_ACTIVE_DAY_TRIP": {
            return {
                ...state,
                isLoading: true,
                active: [],
            };
        }
        case "GET_INACTIVE_DAY_TRIP": {
            return {
                ...state,
                isLoading: true,
                inactive: [],
            };
        }
        case "GET_DAY_TRIP_SUCCESS": {
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        }
        case "GET_INACTIVE_DAY_TRIP_SUCCESS": {
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        }
        case "GET_DAY_TRIP_FAIL": {
            return {
                trips: [],
                isLoading: false,
                ...payload
            };
        }
        default:
            return state
    }
}

export default dayTripReducer
