const initialState = {
    schedules: []
}

const scheduleReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case "GET_SCHEDULES": {
            return {
                isLoading: true,
                schedules: []
            };
        }
        case "GET_SCHEDULES_SUCCESS": {
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        }
        case "GET_SCHEDULES_FAIL": {
            return {
                schedules: [],
                isLoading: false,
                ...payload
            };
        }
        default:
            return state
    }
}

export default scheduleReducer
