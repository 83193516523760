import { firestore } from "firebase";

const db = firestore();
const DB = process.env;

export const getPermissionsByUserId = userId => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_PERMISSIONS)
            .where('userId', '==', userId)
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    reject("El usuario no tiene permisos")
                    return;
                }
                resolve(snapshot);
            })
            .catch(error => {
                reject(error)
            });
    });
}
