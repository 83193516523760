import {firestore} from "firebase";

const db = firestore();
const DB = process.env;

export const getContactDataById = userId => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_CONTACTS)
            .doc(userId)
            .get()
            .then(data => {
                resolve(data.data());
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const getAllContactsData = () => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_CONTACTS)
            .get()
            .then(result => {
                let allContacts = [];

                result.forEach(doc => {
                    allContacts.unshift(doc.data());
                });

                allContacts.forEach(function (element) {
                    element.label = `${element['place']} / ${element['selectedMunicipality']} / ${element['contactName']}`;
                });

                resolve(allContacts);
            })
    });
}
