const initialState = {
    allContacts: []
}

const contactsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case "GET_ALL_CONTACTS": {
            return {
                isLoading: true,
                contacts: []
            };
        }
        case "GET_ALL_CONTACTS_SUCCESS": {
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        }
        case "GET_ALL_CONTACTS_FAIL": {
            return {
                contacts: [],
                isLoading: false,
                ...payload
            };
        }
        default:
            return state
    }
}

export default contactsReducer
