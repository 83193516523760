import { call, put, takeEvery } from 'redux-saga/effects'
import {getAllUsersData} from "../../../firebase/User/Getters";
import {getUsersFAIL, getUsersSuccess} from "../../actions/allUsers";


// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getAllUsers() {
    try {
        const users = yield call(getAllUsersData);
        yield put(getUsersSuccess(users));
    } catch (e) {
        yield put(getUsersFAIL(e.message));
    }
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* watchUsersSaga() {
    yield takeEvery("GET_ALL_USERS", getAllUsers);
}

export default watchUsersSaga;
