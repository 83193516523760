import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: 1,
    title: "Panel de control",
    type: "item",
    icon: <Icon.BarChart size={20} />,
    permissions: ["admin", "operator"],
    navLink: "/"
  },
  {
    id: 2,
    title: "Directorio",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    permissions: ["admin", "operator"],
    navLink: "/yellowpages"
  },
  {
    id: 3,
    title: "Servicios",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["admin", "operator"],
    navLink: "/services"
  },
  {
    id: 4,
    title: "Usuarios",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "operator"],
    navLink: "/users"
  },
  {
    id: 5,
    title: "Horarios",
    type: "item",
    icon: <Icon.Watch size={20} />,
    permissions: ["admin", "operator"],
    navLink: "/schedules"
  },
  {
    id: 6,
    title: "Suscriptores",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "operator"],
    navLink: "/subscribers"
  }
]

export default navigationConfig
