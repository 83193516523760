import {all} from "redux-saga/effects"

import watchUsersSaga from "../sagas/allUsers/index";
import watchContactsSaga from "../sagas/contacts/index";
import watchSchedulesSaga from "../sagas/schedules/index";
import watchActiveDayTripsSaga from "./dayTrips/activeDayTrips";
import watchInactiveDayTripsSaga from "./dayTrips/inactiveDayTrips";

export default function* rootSaga() {
    yield all([
        watchUsersSaga(),
        watchContactsSaga(),
        watchSchedulesSaga(),
        watchActiveDayTripsSaga(),
        watchInactiveDayTripsSaga()
    ])
}
