import { combineReducers } from "redux"
import customizer from "./customizer/"
import user from "./user/index"
import navbar from "./navbar/Index"
import dayTrip from "./dayTrip/index"
import allUsers from "./allUsers/index"
import subscribers from "./subscribers/index"
import permissions from "./permissions/index"
import allContacts from "./contacts";
import allSchedules from "./schedules";

const rootReducer = combineReducers({
  user: user,
  allUsers,
  allContacts,
  allSchedules,
  dayTrip,
  subscribers: subscribers,
  permissions: permissions,
  navbar: navbar,
  customizer: customizer
})

export default rootReducer
