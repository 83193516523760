export const getContacts = () => ({
    type: "GET_ALL_CONTACTS",
    payload: {},
});

export const getContactsSuccess = contacts => ({
    type: "GET_ALL_CONTACTS_SUCCESS",
    payload: {
        contacts,
    },
});

export const getContactsFail = error => ({
    type: "GET_ALL_CONTACTS_FAIL",
    payload: {
        error,
    },
});
