import { firestore } from "firebase";

const db = firestore();
const DB = process.env;

export const getScheduleById = scheduleID => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_SCHEDULES)
            .where('uuid', '==', scheduleID)
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                resolve(snapshot);
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const getAllSchedules = () => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_SCHEDULES)
            .get()
            .then(result => {
                if (result.empty) {
                    console.log('No matching documents.');
                    return;
                }

                let schedules = [];

                result.forEach(doc => {
                    schedules.unshift(doc.data());
                });

                schedules.forEach(function (element) {
                    element.label = `${element['name']}`;
                });

                resolve(schedules);
            })
            .catch(error => {
                reject(error)
            });
    });
}
