export const changeCountActiveDayTrip = activeDayTrip => ({
    type: "CHANGE_COUNT_ACTIVE_DAY_TRIP",
    payload: {
        activeDayTrip,
    },
});

export const changeCountInactiveDayTrip = inactiveDayTrip => ({
    type: "CHANGE_COUNT_INACTIVE_DAY_TRIP",
    payload: {
        inactiveDayTrip,
    },
});

export const changeActiveDayTripList = activeDayTripList => ({
    type: "CHANGE_ACTIVE_DAY_TRIP_LIST",
    payload: {
        activeDayTripList,
    },
});

export const changeInactiveDayTripList = inactiveDayTripList => ({
    type: "CHANGE_INACTIVE_DAY_TRIP_LIST",
    payload: {
        inactiveDayTripList,
    },
});

// Action creators from redux-saga
export const getDayTrip = payload => ({
    type: "GET_DAY_TRIP",
    payload,
});

export const getInactiveDayTrip = payload => ({
    type: "GET_INACTIVE_DAY_TRIP",
    payload,
});

export const getDayTripSuccess = active => ({
    type: "GET_DAY_TRIP_SUCCESS",
    payload: {
        active,
    },
});

export const getInactiveDayTripSuccess = inactive => ({
    type: "GET_INACTIVE_DAY_TRIP_SUCCESS",
    payload: {
        inactive,
    },
});

export const getDayTripFail = error => ({
    type: "GET_DAY_TRIP_FAIL",
    payload: {
        error,
    },
});

