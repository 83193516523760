const initialState = {}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_ALL_SUBSCRIBERS": {
            const { subscribers } = action.payload;
            return {
                ...state,
                ...subscribers,
            };
        }
        default:
            return state
    }
}

export default userReducer
