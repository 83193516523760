import React from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"

// Firebase
import '../../../firebase/Config'
import {LogOut} from '../../../firebase/Credentials/LogOut';

// Redux
import {bindActionCreators} from "redux";
import {changeLogIn} from "../../../redux/actions/user";
import {deleteUserPermissions} from "../../../redux/actions/permissions";
import {connect} from "react-redux";

// Notifications
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import {toast} from "react-toastify";
import {ToastContainer} from "react-toastify";


const UserDropdown = props => {
    return (
        <DropdownMenu right>
            <DropdownItem tag="a" href="#">
                <Icon.User size={14} className="mr-50"/>
                <span className="align-middle">Perfil</span>
            </DropdownItem>
            <DropdownItem tag="a"  onClick={() => history.push(`/tickets`)}>
                <Icon.Mail size={14} className="mr-50"/>
                <span className="align-middle">Tickets</span>
            </DropdownItem>

            <DropdownItem divider/>

            <DropdownItem
                tag="a"
                href="#"
                onClick={props.closeSession}
            >
                <Icon.Power size={14} className="mr-50"/>
                <span className="align-middle">Cerrar sesión</span>
            </DropdownItem>
        </DropdownMenu>
    )
}

class NavbarUser extends React.PureComponent {
    state = {}

    componentDidMount() {
    }

    closeSession = () => {
        const {changeLogIn, deleteUserPermissions} = this.props;

        // change user permissions in redux
        deleteUserPermissions([]);

        LogOut()
            .then(function () {
                toast.success("Cerrando sesión", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });

                // Redux state
                changeLogIn(false);
            })
            .catch((error) => {
                changeLogIn(true)
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
            });
    }


    render() {
        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">
                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                        <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userLastname}, {this.props.userName}
              </span>
                            <span className="user-status">Available</span>
                        </div>
                        <span data-tour="user">
              <img
                  src={this.props.userPicture ? this.props.userPicture : "https://i.pinimg.com/736x/0e/1d/c9/0e1dc94d35c9911c660d95ead7485415.jpg"}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
              />
            </span>
                    </DropdownToggle>
                    <UserDropdown closeSession={this.closeSession} {...this.props} />
                </UncontrolledDropdown>
                <ToastContainer/>
            </ul>
        )
    }
}

const mapStateToProps = state => {
    const {isLogIn, userName, userLastname, userUID} = state.user;
    return {
        isLogIn,
        userLastname,
        userName,
        userUID
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeLogIn, deleteUserPermissions
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavbarUser)
