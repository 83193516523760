import {firestore} from "firebase";
import toNumber from 'lodash/toNumber';

const db = firestore();
const DB = process.env;

export const getDayTrips = () => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_DAY_TRIP)
            .where('status', '==', "active")
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                resolve(snapshot);
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const getDayTripsByState = state => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_DAY_TRIP)
            .where('status', '==', state)
            // .limit(5)
            .get()
            .then(result => {
                let dayTrips = [];

                if (result.empty) {
                    console.log('No matching documents.');
                    return;
                }

                result.forEach(doc => {
                    dayTrips.unshift(doc.data());
                });

                resolve(dayTrips);
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const getDayTripsByYear = year => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_DAY_TRIP)
            .where('status', '==', 'inactive')
            .where('year', '==', toNumber(year))
            .get()
            .then(result => {
                let dayTrips = [];
                if (result.empty) {
                    console.log('No matching documents.');
                    return;
                }

                result.forEach(doc => {
                    dayTrips.unshift(doc.data());
                });
                resolve(dayTrips);
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const getInactiveDayTrips = () => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_DAY_TRIP)
            .where('status', '==', "inactive")
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                resolve(snapshot);
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const getSpecificDayTrip = uuid => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_DAY_TRIP)
            .doc(uuid)
            .get()
            .then(data => {
                resolve(data.data());
            })
            .catch(error => {
                reject(error)
            });
    });
}


export const getDayTripsShedulesByDaytripId = uuid => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_DAY_TRIP_SCHEDULE)
            .where('tripId', '==', uuid)
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                resolve(snapshot);
            })
            .catch(error => {
                reject(error)
            });
    });
}
