import { call, put, take} from 'redux-saga/effects'
import {getDayTripFail, getInactiveDayTripSuccess} from "../../actions/dayTrip";
import {getDayTripsByYear} from "../../../firebase/DayTrip/Getters";


// worker Saga: will be fired on GET_DAY_TRIP actions
function* getInactiveDayTrips({year}) {
    try {
        const trips = yield call(getDayTripsByYear, year);
        yield put(getInactiveDayTripSuccess(trips));
    } catch (e) {
        yield put(getDayTripFail(e.message));
    }
}

/*
  Starts fetchUser on each dispatched `GET_DAY_TRIP` action.
  Allows concurrent fetches of user.
*/
function* watchInactiveDayTripsSaga() {
    const {payload} = yield take("GET_INACTIVE_DAY_TRIP");
    yield call(getInactiveDayTrips, payload)
}

export default watchInactiveDayTripsSaga;
