import {firestore} from "firebase";

const db = firestore();
const DB = process.env;
console.log(process.env)
export const getUserData = userId => {
    return new Promise((resolve, reject) => {
        db
            .collection(DB.REACT_APP_DB_USERS)
            .doc(userId)
            .get()
            .then(data => {
                resolve(data.data());
            })
            .catch(error => {
                reject(error)
            });
    });
}

export const getAllUsersData = () => {
    return new Promise((resolve) => {
        db
            .collection(DB.REACT_APP_DB_USERS)
            .get()
            .then(result => {
                let users = [];

                result.forEach(doc => {
                    users.unshift(doc.data());
                });

                users.forEach(function (element) {
                    element.label = `${element['name']} ${element['lastname']}`;
                });

                resolve(users);
            })
    });
}
